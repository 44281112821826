<template lang="pug">
  v-container
    v-toolbar(
      color='primary'
      dark
    )
      span New Supplier
    v-alert(
      type='success'
      dismissible
      v-if='splrCatPostSuccess'
    )
      span {{ splrCatPostSuccess }}
    v-alert(
      type='error'
      dismissible
      v-for='(item, index) in splrCatPostErrors'
      :key='index'
    )
      span.text-uppercase {{ item.first() }}
    v-row
      v-col(cols='12')
        v-text-field(
          label='Category ID'
          v-model='supplierCategoryId'
        )
      v-col(cols='12')
        v-text-field(
          label='Description'
          v-model='description'
        )
      v-col(cols='12')
        v-btn(
          :loading='splrCatPosting'
          color='primary'
          @click='storeSplrCat'
        )
          span Save
</template>
<script>
import splrCatRepository from '@/repositories/supplier-category.repository'
import { postVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const splrCatStoreDelay = searchDelay()

const inputVars = () => ({
  supplierCategoryId: null,
  description: null,
})

export default {
  name: 'CreateSupplierCategory',
  data () {
    return {
      ...inputVars(),
      ...postVars('splr-cat', { success: null }),
    }
  },
  methods: {
    storeSplrCat () {
      if (this.splrCatPosting) return
      this.splrCatPosting = true
      this.splrCatPostErrors = []
      this.splrCatPostSuccess = null
      splrCatStoreDelay(() => {
        const data = this.getInputVars()
        splrCatRepository.store(data)
          .then(this.storeSplrCatSuccess)
          .catch(e => { this.splrCatPostErrors = errorHandler(e) })
          .then(() => { this.splrCatPosting = false })
      })
    },
    storeSplrCatSuccess () {
      const { supplierCategoryId, description } = this
      this.splrCatPostSuccess = `Supplier Category ${supplierCategoryId}: ${description} Store Successfully`
      this.reset(inputVars())
    },
    reset (vars) {
      Object.keys(vars).forEach(e => {
        this[e] = vars[e]
      })
    },
    getInputVars () {
      return {
        id: this.supplierCategoryId,
        description: this.description,
      }
    },
  },
}
</script>
